import { t } from "i18next";
import { doRedirect } from "../doRedirect";
function researchEndErrorUser(configData, setInfo) {
    var _a, _b, _c;
    setInfo({
        button: t("Wróć do badania"),
        buttonUrl: (_c = (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.redirect) === null || _b === void 0 ? void 0 : _b.userError) !== null && _c !== void 0 ? _c : "",
        message: t("Ankieta w sklepie wirtualnym zakończyła się"),
        messageBottom: t("Nastąpi przekierowanie do pozostałej części ankiety. Jeśli nie wydarzy się to automatycznie, proszę kliknąć >wróć do badania<"),
    });
    setTimeout(function () {
        var _a, _b, _c;
        doRedirect((_c = (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.redirect) === null || _b === void 0 ? void 0 : _b.userError) !== null && _c !== void 0 ? _c : "");
    }, 3000);
}
export default researchEndErrorUser;
