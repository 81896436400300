import translationCZ from "./cz/translation.json";
import translationDE from "./de/translation.json";
import translationEN from "./en/translation.json";
import translationNL from "./nl/translation.json";
import translationPL from "./pl/translation.json";
export var resources = {
    de: {
        translation: translationDE,
    },
    en: {
        translation: translationEN,
    },
    es: {
        translation: translationEN,
    },
    pl: {
        translation: translationPL,
    },
    cz: {
        translation: translationCZ,
    },
    nl: {
        translation: translationNL,
    },
};
