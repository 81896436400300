import { t } from "i18next";
import { doRedirect } from "../doRedirect";
import Cookies from "js-cookie";
import { pushAbtShield } from "../abtShield";
import logThestore from "../logThestore";
function researchEndErrorTech(configData, setInfo, setErrorInfo, stage) {
    var _a, _b, _c, _d, _e, _f;
    var cookieName = "countError2";
    var countError2 = Number.parseInt((_a = Cookies.get(cookieName)) !== null && _a !== void 0 ? _a : "0");
    if (isNaN(countError2)) {
        countError2 = 0;
    }
    if (countError2 < 3) {
        var expireDate = new Date();
        expireDate.setHours(expireDate.getHours() + 2);
        Cookies.set(cookieName, (countError2 + 1).toString(), {
            expires: expireDate,
            path: "",
        });
        setErrorInfo({
            uid: (_c = (_b = configData === null || configData === void 0 ? void 0 : configData.content) === null || _b === void 0 ? void 0 : _b.user) === null || _c === void 0 ? void 0 : _c.uid,
            button: t("Ok, rozumiem"),
            buttonUrl: window.location.href,
            message: t("Wystąpił problem techniczny. Ankieta w sklepie wirtualnym zaczyna się od nowa"),
        });
        pushAbtShield(["send", "event", "reload"]);
        logThestore({
            configData: configData,
            event: "event",
            level: "info",
            payload: {
                stage: stage,
                message: "reload",
            },
        });
    }
    else {
        setInfo({
            button: t("Wróć do badania"),
            buttonUrl: (_f = (_e = (_d = configData.content) === null || _d === void 0 ? void 0 : _d.redirect) === null || _e === void 0 ? void 0 : _e.technicalError) !== null && _f !== void 0 ? _f : "",
            message: t("Ankieta w sklepie wirtualnym zakończyła się"),
            messageBottom: t("Nastąpi przekierowanie do pozostałej części ankiety. Jeśli nie wydarzy się to automatycznie, proszę kliknąć >wróć do badania<"),
        });
        Cookies.remove(cookieName);
        setTimeout(function () {
            var _a, _b, _c;
            doRedirect((_c = (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.redirect) === null || _b === void 0 ? void 0 : _b.technicalError) !== null && _c !== void 0 ? _c : "");
        }, 3000);
    }
}
export default researchEndErrorTech;
