import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { CULTURES } from "../locales/locales.types";
import isDemo from "./isDemo";
var useSetLanguage = function (configData) {
    var i18n = useTranslation().i18n;
    useEffect(function () {
        var _a, _b, _c, _d;
        if (!configData) {
            return;
        }
        var language = isDemo
            ? (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.demo) === null || _b === void 0 ? void 0 : _b.culture
            : (_d = (_c = configData.content) === null || _c === void 0 ? void 0 : _c.research) === null || _d === void 0 ? void 0 : _d.culture;
        if (language && CULTURES.includes(language)) {
            void i18n.changeLanguage(language);
        }
        else {
            void i18n.changeLanguage("pl");
        }
    }, [configData]);
};
export default useSetLanguage;
