var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import "./App.css";
import { PlatformNext, Resolution } from "@pureweb/platform-sdk";
import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import qs from "query-string";
import React, { useCallback, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import { initReactI18next, useTranslation } from "react-i18next";
import { ClientOptions } from "./Classes";
import { Stage, useApp } from "./components/AppContext";
import DemoHeader from "./components/DemoHeader";
import { ErrorInfoContainer } from "./components/ErrorInfoContainer";
import ProgressBar from "./components/ProgressBar";
import PureWeb from "./components/PureWeb";
import ScreenRotationOverlay from "./components/ScreenRotationOverlay";
import { resources } from "./locales/resources";
import setupAbtShield from "./utilities/abtShield";
import { getQueryBootString } from "./utilities/bootString";
import isDemo from "./utilities/isDemo";
import logThestore, { appHost, setLogData } from "./utilities/logThestore";
import { System } from "./utilities/system";
import useSetLanguage from "./utilities/useSetLanguage";
import { InfoContainer } from "./components/InfoContainer";
void i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
    fallbackLng: "pl",
    interpolation: {
        escapeValue: false,
    },
    resources: resources,
});
var AppWrapper = function () {
    var _a, _b, _c, _d;
    var _e = useState(true), loading = _e[0], setLoading = _e[1];
    var _f = useState(), configData = _f[0], setConfigData = _f[1];
    var _g = useState(new ClientOptions()), clientOptions = _g[0], setClientOptions = _g[1];
    var _h = useState(new PlatformNext()), platform = _h[0], setPlatform = _h[1];
    var t = useTranslation().t;
    var _j = useState(), displayOverlay = _j[0], setDisplayOverlay = _j[1];
    var _k = useApp(), info = _k.info, errorInfo = _k.errorInfo, setErrorInfo = _k.setErrorInfo, loaderStatus = _k.loaderStatus, setStage = _k.setStage, stage = _k.stage;
    var handleWindowSizeChange = useCallback(function (event) {
        if (event && event.type === "orientationchange") {
            setTimeout(function () {
                setDisplayOverlay(window.innerWidth < window.innerHeight && window.innerWidth < 768);
            }, 500);
        }
        else {
            setDisplayOverlay(window.innerWidth < window.innerHeight && window.innerWidth < 768);
        }
    }, []);
    var handleWindowClose = useCallback(function (event) {
        if (isDemo || window._link_was_clicked) {
            return undefined;
        }
        if (!navigator.sendBeacon) {
            // @ts-expect-error
            navigator.sendBeacon = function (url_1) {
                var args_1 = [];
                for (var _i = 1; _i < arguments.length; _i++) {
                    args_1[_i - 1] = arguments[_i];
                }
                return __awaiter(void 0, __spreadArray([url_1], args_1, true), void 0, function (url, data) {
                    if (data === void 0) { data = null; }
                    return __generator(this, function (_a) {
                        switch (_a.label) {
                            case 0: return [4 /*yield*/, window.fetch(url, {
                                    method: "POST",
                                    body: setLogData(configData, "beforeunload", "warning", { stage: stage }),
                                })];
                            case 1:
                                _a.sent();
                                return [2 /*return*/];
                        }
                    });
                });
            };
        }
        navigator.sendBeacon(appHost + "/api/log", setLogData(configData, "beforeunload", "warning", { stage: stage }));
        window.removeEventListener("beforeunload", handleWindowClose);
        return undefined;
    }, [configData]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h;
        if (!configData) {
            return;
        }
        window.addEventListener("beforeunload", handleWindowClose);
        document.addEventListener("click", function (event) {
            if (this.nodeName.toLowerCase() === "a") {
                window._link_was_clicked = true;
            }
        }, true);
        if (!((_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.research) === null || _b === void 0 ? void 0 : _b.allowMobile) && System.isMobile()) {
            logThestore({
                configData: configData,
                event: "error_project_not_allow_mobile",
                level: "warning",
                payload: {
                    stage: stage,
                },
            });
            setErrorInfo({
                uid: (_d = (_c = configData === null || configData === void 0 ? void 0 : configData.content) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.uid,
                message: t("Symulacja zakupów w wirtualnym sklepie działa najlepiej na laptopach lub komputerach stacjonarnych. By w pełni skorzystać z tej funkcji, odwiedź stronę na jednym z tych urządzeń. " +
                    "Jeśli bierzesz udział w badaniu konsumenckim, rozpocznij je ponownie na laptopie lub komputerze."),
            });
        }
        if (System.isWebview()) {
            logThestore({
                configData: configData,
                event: "error_browser_is_webview",
                level: "warning",
                payload: {
                    stage: stage,
                },
            });
            setErrorInfo({
                uid: (_f = (_e = configData === null || configData === void 0 ? void 0 : configData.content) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.uid,
                message: t("Aby właściwie uruchomić badanie, kliknij w menu kontekstowe..."),
            });
        }
        else if (!System.IsBrowserSupported()) {
            logThestore({
                configData: configData,
                event: "error_browser_not_supported",
                level: "warning",
                payload: {
                    stage: stage,
                },
            });
            setErrorInfo({
                uid: (_h = (_g = configData === null || configData === void 0 ? void 0 : configData.content) === null || _g === void 0 ? void 0 : _g.user) === null || _h === void 0 ? void 0 : _h.uid,
                message: t("Twoja przeglądarka nie jest obsługiwana"),
            });
        }
        else {
            handleWindowSizeChange(null);
            window.addEventListener("resize", handleWindowSizeChange, false);
            window.addEventListener("orientationchange", handleWindowSizeChange, false);
            return function () {
                window.removeEventListener("resize", handleWindowSizeChange);
                window.removeEventListener("orientationchange", handleWindowSizeChange);
            };
        }
    }, [handleWindowSizeChange, configData, loading]);
    useEffect(function () {
        var configDataFetch = function () { return __awaiter(void 0, void 0, Promise, function () {
            var appHost, apiUrl, configData_1, error_1, message;
            var _a, _b, _c, _d, _e, _f, _g, _h, _j;
            return __generator(this, function (_k) {
                switch (_k.label) {
                    case 0:
                        appHost = window.location.protocol + "//" + window.location.hostname;
                        _k.label = 1;
                    case 1:
                        _k.trys.push([1, 4, , 5]);
                        apiUrl = "/api/get_configuration?query=" + getQueryBootString();
                        if (isDemo) {
                            apiUrl =
                                "/api/get_demo_configuration?domain=" + window.location.hostname;
                        }
                        return [4 /*yield*/, fetch(appHost + apiUrl)];
                    case 2: return [4 /*yield*/, (_k.sent()).json()];
                    case 3:
                        configData_1 = _k.sent();
                        if (configData_1.status === "ok") {
                            setConfigData(configData_1);
                            setStage(Stage.ConfigOk);
                            if (!isDemo) {
                                setupAbtShield(configData_1);
                                document.title = t("Badanie");
                            }
                            else {
                                if ((_b = (_a = configData_1.content) === null || _a === void 0 ? void 0 : _a.demo) === null || _b === void 0 ? void 0 : _b.title) {
                                    document.title = (_d = (_c = configData_1.content) === null || _c === void 0 ? void 0 : _c.demo) === null || _d === void 0 ? void 0 : _d.title;
                                }
                            }
                        }
                        else {
                            throw new Error(JSON.stringify(configData_1.content), {
                                cause: (_e = configData_1.content) === null || _e === void 0 ? void 0 : _e.code,
                            });
                        }
                        return [3 /*break*/, 5];
                    case 4:
                        error_1 = _k.sent();
                        logThestore({
                            configData: configData,
                            event: "error_config_data",
                            level: "error",
                            payload: {
                                stage: stage,
                                message: error_1.message,
                            },
                        });
                        if (isDemo) {
                            setErrorInfo({
                                uid: (_g = (_f = configData === null || configData === void 0 ? void 0 : configData.content) === null || _f === void 0 ? void 0 : _f.user) === null || _g === void 0 ? void 0 : _g.uid,
                                message: t("Demo, które uruchomiłeś nie jest aktualnie dostępne online. Skontaktuj się przedstawicielem Sidnet."),
                            });
                        }
                        else {
                            logThestore({
                                configData: configData,
                                event: "error_config_data",
                                level: "error",
                                payload: {
                                    message: error_1.message,
                                },
                            });
                            message = t("Wystąpił problem po stronie serwera, należy odświeżyć tę stronę, by rozpocząć badanie");
                            if (error_1.cause === "ENDING_LINK_NOT_FOUND") {
                                message = t("Wystąpił problem po stronie serwera, nie znaleziono linka końcowego (source) w bazie danych.");
                            }
                            else if (error_1.cause === "PROJECT_NOT_FOUND") {
                                message = t("Wystąpił problem po stronie serwera, Nie znaleziono projektu w bazie danych.");
                            }
                            else if (error_1.cause === "PROJECT_NOT_ACTIVE") {
                                message = t("Wystąpił problem po stronie serwera, ten projekt jest już nieaktywny.");
                            }
                            setErrorInfo({
                                uid: (_j = (_h = configData === null || configData === void 0 ? void 0 : configData.content) === null || _h === void 0 ? void 0 : _h.user) === null || _j === void 0 ? void 0 : _j.uid,
                                button: t("Ok, rozumiem"),
                                buttonUrl: window.location.href,
                                message: message,
                            });
                        }
                        return [3 /*break*/, 5];
                    case 5: return [2 /*return*/];
                }
            });
        }); };
        void configDataFetch();
    }, []);
    useSetLanguage(configData);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w, _x, _y, _z, _0, _1, _2, _3, _4, _5, _6, _7, _8, _9;
        if (!configData) {
            return;
        }
        // Parse query parameters
        var query = qs.parse(window.location.search);
        clientOptions.LaunchType =
            (_a = query.launchType) !== null && _a !== void 0 ? _a : (_c = (_b = configData === null || configData === void 0 ? void 0 : configData.content) === null || _b === void 0 ? void 0 : _b.pureweb) === null || _c === void 0 ? void 0 : _c.launchType;
        if (query.collaboration && query.collaboration === "true") {
            clientOptions.LaunchType = "local";
        }
        var uid = (_e = (_d = configData.content) === null || _d === void 0 ? void 0 : _d.user) === null || _e === void 0 ? void 0 : _e.uid;
        if (uid !== null && uid !== undefined) {
            document.body.setAttribute("uid", uid);
        }
        clientOptions.Endpoint =
            (_f = query.endpoint) !== null && _f !== void 0 ? _f : (_h = (_g = configData === null || configData === void 0 ? void 0 : configData.content) === null || _g === void 0 ? void 0 : _g.pureweb) === null || _h === void 0 ? void 0 : _h.endpoint;
        clientOptions.ProjectId =
            (_j = query.projectId) !== null && _j !== void 0 ? _j : (_l = (_k = configData === null || configData === void 0 ? void 0 : configData.content) === null || _k === void 0 ? void 0 : _k.pureweb) === null || _l === void 0 ? void 0 : _l.project;
        clientOptions.ModelId =
            (_m = query.modelId) !== null && _m !== void 0 ? _m : (_p = (_o = configData === null || configData === void 0 ? void 0 : configData.content) === null || _o === void 0 ? void 0 : _o.pureweb) === null || _p === void 0 ? void 0 : _p.model;
        clientOptions.Version =
            (_q = query.version) !== null && _q !== void 0 ? _q : (_s = (_r = configData === null || configData === void 0 ? void 0 : configData.content) === null || _r === void 0 ? void 0 : _r.pureweb) === null || _s === void 0 ? void 0 : _s.version;
        clientOptions.EnvironmentId =
            (_t = query.environmentId) !== null && _t !== void 0 ? _t : (_v = (_u = configData === null || configData === void 0 ? void 0 : configData.content) === null || _u === void 0 ? void 0 : _u.pureweb) === null || _v === void 0 ? void 0 : _v.environmentId;
        clientOptions.Resolution =
            (_w = query.resolution) !== null && _w !== void 0 ? _w : (_y = (_x = configData === null || configData === void 0 ? void 0 : configData.content) === null || _x === void 0 ? void 0 : _x.pureweb) === null || _y === void 0 ? void 0 : _y.resolution;
        clientOptions.Resolution = (_z = clientOptions.Resolution) !== null && _z !== void 0 ? _z : Resolution.fhd;
        // use client json config if usePointerLock query string parameter is undefined, else use query string parameter. Default to false if non are present
        clientOptions.UsePointerLock =
            (_2 = (query.usePointerLock === undefined
                ? (_1 = (_0 = configData === null || configData === void 0 ? void 0 : configData.content) === null || _0 === void 0 ? void 0 : _0.pureweb) === null || _1 === void 0 ? void 0 : _1.usePointerLock
                : query.usePointerLock === "true")) !== null && _2 !== void 0 ? _2 : true;
        // release the pointer lock on mouse up if true
        clientOptions.PointerLockRelease =
            (_5 = (query.pointerLockRelease === undefined
                ? (_4 = (_3 = configData === null || configData === void 0 ? void 0 : configData.content) === null || _3 === void 0 ? void 0 : _3.pureweb) === null || _4 === void 0 ? void 0 : _4.pointerLockRelease
                : query.pointerLockRelease === "true")) !== null && _5 !== void 0 ? _5 : false;
        clientOptions.ForceRelay = (_6 = query.forceRelay !== undefined) !== null && _6 !== void 0 ? _6 : false;
        clientOptions.UseNativeTouchEvents =
            (_9 = (query.useNativeTouchEvents === undefined
                ? (_8 = (_7 = configData === null || configData === void 0 ? void 0 : configData.content) === null || _7 === void 0 ? void 0 : _7.pureweb) === null || _8 === void 0 ? void 0 : _8.useNativeTouchEvents
                : query.useNativeTouchEvents === "true")) !== null && _9 !== void 0 ? _9 : isMobile;
        setClientOptions(clientOptions);
        // Initialize platform reference
    }, [clientOptions, configData]);
    useEffect(function () {
        var _a;
        if (!configData || !System.IsBrowserSupported() || System.isWebview()) {
            return;
        }
        platform.initialize({
            endpoint: (_a = clientOptions.Endpoint) !== null && _a !== void 0 ? _a : "https://api.pureweb.io",
        });
        setPlatform(platform);
        setLoading(false);
    }, [platform, clientOptions, configData]);
    useEffect(function () {
        if (configData && !loading) {
            window.removeEventListener("resize", handleWindowSizeChange);
            window.removeEventListener("orientationchange", handleWindowSizeChange);
        }
    }, [configData, loading, handleWindowSizeChange]);
    return (_jsxs(_Fragment, { children: [(((_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.demo) === null || _b === void 0 ? void 0 : _b.showEdgeLogo) === true ||
                ((_d = (_c = configData === null || configData === void 0 ? void 0 : configData.content) === null || _c === void 0 ? void 0 : _c.demo) === null || _d === void 0 ? void 0 : _d.showBrandLogo) === true) &&
                loaderStatus !== "hide" && _jsx(DemoHeader, { configData: configData }), displayOverlay ? (_jsx(ScreenRotationOverlay, { removeOverlay: function () {
                    setDisplayOverlay(false);
                } })) : errorInfo ? (_jsx(ErrorInfoContainer, {})) : info ? (_jsx(InfoContainer, {})) : (_jsxs(_Fragment, { children: [_jsx(ProgressBar, {}), configData && !loading && (_jsx(PureWeb, { clientOptions: clientOptions, configData: configData, platform: platform }))] }))] }));
};
export default AppWrapper;
