import isDemo from "./isDemo";
import { System } from "./system";
export default function logThestore(params) {
    var configData = params.configData, event = params.event, level = params.level, payload = params.payload;
    if (isDemo) {
        return;
    }
    void fetch(appHost + "/api/log", {
        body: setLogData(configData, event, level, payload),
        method: "POST",
    }).catch(function (e) { });
}
export function setLogData(configData, event, level, payload) {
    var _a, _b, _c, _d, _e, _f;
    return JSON.stringify({
        event: event,
        level: level,
        payload: payload,
        time: Date.now(),
        uid: (_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
        respId: (_d = (_c = configData === null || configData === void 0 ? void 0 : configData.content) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.respId,
        project: (_f = (_e = configData === null || configData === void 0 ? void 0 : configData.content) === null || _e === void 0 ? void 0 : _e.research) === null || _f === void 0 ? void 0 : _f.project,
        browser: System.getBrowserData(),
    });
}
export var appHost = window.location.protocol + "//" + window.location.hostname;
