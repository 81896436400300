import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "semantic-ui-react";
import { useApp } from "./AppContext";
export var InfoContainer = function () {
    var info = useApp().info;
    return info ? (_jsxs("div", { style: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            overflow: "none",
            textAlign: "center",
        }, children: [_jsx("h2", { children: info.message }), info.button && (_jsx("div", { children: _jsx(Button, { primary: true, as: "a", href: info.buttonUrl, children: _jsx(Button.Content, { visible: true, children: info.button }) }) })), info.messageBottom && _jsx("h3", { children: info.messageBottom })] })) : (_jsx("div", {}));
};
