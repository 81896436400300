import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import "./DemoHeader.css";
var DemoHeader = function (properties) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m;
    var configData = properties.configData;
    var brandLogo, edgeLogo;
    var appHost = window.location.protocol + "//" + window.location.hostname;
    if ((_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.demo) === null || _b === void 0 ? void 0 : _b.showBrandLogo) {
        brandLogo = (_jsx("div", { className: "brandLogo", children: _jsx("a", { href: (_d = (_c = configData.content) === null || _c === void 0 ? void 0 : _c.demo) === null || _d === void 0 ? void 0 : _d.brandUrl, children: _jsx("img", { src: appHost + ((_f = (_e = configData.content) === null || _e === void 0 ? void 0 : _e.demo) === null || _f === void 0 ? void 0 : _f.brandLogoFile), alt: "brand logo" }) }) }));
    }
    if ((_h = (_g = configData.content) === null || _g === void 0 ? void 0 : _g.demo) === null || _h === void 0 ? void 0 : _h.showEdgeLogo) {
        edgeLogo = (_jsx("div", { className: "edgeLogo", children: _jsx("a", { href: (_k = (_j = configData.content) === null || _j === void 0 ? void 0 : _j.demo) === null || _k === void 0 ? void 0 : _k.edgeUrl, children: _jsx("img", { src: (_m = (_l = configData.content) === null || _l === void 0 ? void 0 : _l.demo) === null || _m === void 0 ? void 0 : _m.edgeLogoUrl, alt: "edge logo" }) }) }));
    }
    return (_jsxs("div", { className: "demoHeader", children: [edgeLogo, " ", brandLogo] }));
};
export default DemoHeader;
