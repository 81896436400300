import { jsxs as _jsxs, jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { Button } from "semantic-ui-react";
import { useApp } from "./AppContext";
import isDemo from "../utilities/isDemo";
import isProduction from "../utilities/isProduction";
var DebugOptions = function (properties) {
    var _a = useApp(), pointerLockRelease = _a.pointerLockRelease, setPointerLockRelease = _a.setPointerLockRelease, usePointerLock = _a.usePointerLock, setUsePointerLock = _a.setUsePointerLock;
    var handleOnClickPointerLockRelease = function () {
        if (pointerLockRelease === undefined) {
            setPointerLockRelease(!properties.defaultPointerLockRelease);
        }
        else {
            setPointerLockRelease(!pointerLockRelease);
        }
    };
    var handleOnClickUsePointerLock = function () {
        if (usePointerLock === undefined) {
            setUsePointerLock(!properties.defaultUsePointerLock);
        }
        else {
            setUsePointerLock(!usePointerLock);
        }
    };
    if (isDemo || isProduction) {
        return;
    }
    return (_jsxs(_Fragment, { children: [_jsx(Button, { style: { position: "absolute", right: 10, top: 120 }, onClick: handleOnClickPointerLockRelease, children: _jsxs("span", { children: ["Set pointerLockRelease", " ", pointerLockRelease !== undefined
                            ? "".concat(pointerLockRelease)
                            : "".concat(properties.defaultPointerLockRelease)] }) }), _jsx(Button, { style: { position: "absolute", right: 10, top: 160 }, onClick: handleOnClickUsePointerLock, children: _jsxs("span", { children: ["Set usePointerLock", " ", usePointerLock !== undefined
                            ? "".concat(usePointerLock)
                            : "".concat(properties.defaultUsePointerLock)] }) })] }));
};
export default DebugOptions;
