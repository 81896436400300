import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import "./../App.css";
import { StreamerStatus, streamResolutionConfiguration, } from "@pureweb/platform-sdk";
import { IdleTimeout, System, VideoStream } from "@pureweb/platform-sdk-react";
import React, { useEffect, useRef } from "react";
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import { Button, Icon } from "semantic-ui-react";
import { pushAbtShield } from "../utilities/abtShield";
import { useApp } from "./AppContext";
import DebugOptions from "./DebugOptions";
var EmbeddedView = function (properties) {
    var videoReference = useRef(null);
    var handle = useFullScreenHandle();
    // Fullscreen API presently supported on iPad, but not iPhone or iPod
    var isIPhone = System.Browser().os === "iOS" &&
        !window.navigator.userAgent.includes("iPad");
    var _a = useApp(), loaderStatus = _a.loaderStatus, pointerLockRelease = _a.pointerLockRelease, usePointerLock = _a.usePointerLock;
    useEffect(function () {
        pushAbtShield(["send", "event", "front_was_loaded"]);
    }, []);
    return (_jsx("div", { style: { height: "100%" }, children: _jsxs(FullScreen, { handle: handle, children: [_jsx(IdleTimeout, { ExitCallback: function () {
                        window.location.reload();
                    }, ExitThreshold: 120, Status: properties.StreamerStatus, WarningCallback: handle.exit, WarningThreshold: 300 }), _jsx(VideoStream, { Emitter: properties.InputEmitter, PointerLockRelease: pointerLockRelease !== null && pointerLockRelease !== void 0 ? pointerLockRelease : properties.PointerLockRelease, Resolution: streamResolutionConfiguration(properties.Resolution), Stream: properties.VideoStream, UseNativeTouchEvents: properties.UseNativeTouchEvents, UsePointerLock: usePointerLock !== null && usePointerLock !== void 0 ? usePointerLock : properties.UsePointerLock, VideoRef: videoReference }), _jsx(Button, { style: { position: "absolute", right: 10, top: 10 }, className: isIPhone ||
                        handle.active ||
                        properties.StreamerStatus !== StreamerStatus.Connected ||
                        loaderStatus !== "hide"
                        ? "hidden"
                        : "", onClick: handle.enter, children: _jsx(Icon, { name: "expand" }) }), _jsx(DebugOptions, { defaultPointerLockRelease: properties.PointerLockRelease, defaultUsePointerLock: properties.UsePointerLock })] }) }));
};
export default EmbeddedView;
