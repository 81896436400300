import { jsx as _jsx } from "react/jsx-runtime";
import { createContext, useContext, useState, } from "react";
export var Stage;
(function (Stage) {
    Stage["Start"] = "start";
    Stage["ConfigOk"] = "configOk";
    Stage["ResearchInit"] = "researchInit";
    Stage["ProgressStep50"] = "progressStep50";
    Stage["ProgressFinished"] = "progressFinished";
    Stage["ResearchEnd"] = "researchEnd";
})(Stage || (Stage = {}));
var AppContext = createContext({
    loaderStatus: 0,
    setLoaderStatus: function () { return undefined; },
    stage: Stage.Start,
    setStage: function () { return undefined; },
    streamerStatus: undefined,
    setStreamerStatus: function () { return undefined; },
    launchRequestStatusType: undefined,
    setLaunchRequestStatusType: function () { return undefined; },
    errorInfo: undefined,
    setErrorInfo: function () { return undefined; },
    info: undefined,
    setInfo: function () { return undefined; },
    usePointerLock: undefined,
    setUsePointerLock: function () { return undefined; },
    pointerLockRelease: undefined,
    setPointerLockRelease: function () { return undefined; },
});
export function AppProvider(_a) {
    var children = _a.children;
    var _b = useState("hide"), loaderStatus = _b[0], setLoaderStatus = _b[1];
    var _c = useState(undefined), streamerStatus = _c[0], setStreamerStatus = _c[1];
    var _d = useState(undefined), launchRequestStatusType = _d[0], setLaunchRequestStatusType = _d[1];
    var _e = useState(undefined), errorInfo = _e[0], setErrorInfo = _e[1];
    var _f = useState(undefined), info = _f[0], setInfo = _f[1];
    var _g = useState(Stage.Start), stage = _g[0], setStage = _g[1];
    var _h = useState(undefined), usePointerLock = _h[0], setUsePointerLock = _h[1];
    var _j = useState(undefined), pointerLockRelease = _j[0], setPointerLockRelease = _j[1];
    var value = {
        loaderStatus: loaderStatus,
        setLoaderStatus: setLoaderStatus,
        stage: stage,
        setStage: setStage,
        streamerStatus: streamerStatus,
        setStreamerStatus: setStreamerStatus,
        launchRequestStatusType: launchRequestStatusType,
        setLaunchRequestStatusType: setLaunchRequestStatusType,
        errorInfo: errorInfo,
        setErrorInfo: setErrorInfo,
        info: info,
        setInfo: setInfo,
        usePointerLock: usePointerLock,
        setUsePointerLock: setUsePointerLock,
        pointerLockRelease: pointerLockRelease,
        setPointerLockRelease: setPointerLockRelease,
    };
    return _jsx(AppContext.Provider, { value: value, children: children });
}
export function useApp() {
    var context = useContext(AppContext);
    if (!context) {
        throw new Error("useLoading must be used within LoadingProvider");
    }
    return context;
}
