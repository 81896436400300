import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { LaunchStatusType, StreamerStatus } from "@pureweb/platform-sdk";
import React, { useEffect, useState } from "react";
import { Progress } from "semantic-ui-react";
import { useApp } from "./AppContext";
import { t } from "i18next";
var ProgressBar = function () {
    var _a = useState(0), percent = _a[0], setPercent = _a[1];
    var _b = useApp(), loaderStatus = _b.loaderStatus, setLoaderStatus = _b.setLoaderStatus, streamerStatus = _b.streamerStatus, launchRequestStatusType = _b.launchRequestStatusType;
    useEffect(function () {
        if (streamerStatus === StreamerStatus.Connected) {
            // setShowLoader(false);
        }
        else if (!launchRequestStatusType) {
            setLoaderStatus(0);
        }
        var maxPercent = 10;
        var minPercent = 0;
        var incrementPercent = function () {
            switch (launchRequestStatusType) {
                case "unknown": {
                    maxPercent = 10;
                    break;
                }
                case "accepted": {
                    maxPercent = 25;
                    minPercent = 10;
                    break;
                }
                case "queued": {
                    maxPercent = 70;
                    minPercent = 25;
                    break;
                }
                case "requested": {
                    maxPercent = 75;
                    minPercent = 70;
                    break;
                }
                case "ready": {
                    maxPercent = 100;
                    minPercent = 75;
                    if (typeof loaderStatus === "number" && loaderStatus !== 0) {
                        minPercent += Math.floor(((maxPercent - minPercent) / 100) * loaderStatus);
                    }
                    break;
                }
                default: {
                    maxPercent = 10;
                    minPercent = 0;
                    clearTimeout(percentTimeout);
                }
            }
            if (percent < minPercent) {
                setPercent(minPercent);
            }
            else if (percent < maxPercent) {
                setPercent(percent + 1);
            }
        };
        var percentTimeout = setTimeout(function () {
            incrementPercent();
        }, 1200);
    }, [launchRequestStatusType, percent, streamerStatus, loaderStatus]);
    if (loaderStatus === "hide") {
        return _jsx("div", {});
    }
    var content = launchRequestStatusType === LaunchStatusType.Unavailable ||
        launchRequestStatusType === LaunchStatusType.Error ||
        streamerStatus === StreamerStatus.Failed ? (_jsx("div", {})) : (_jsxs("div", { children: [_jsx("h4", { children: t(humanReadableStatus[launchRequestStatusType !== null && launchRequestStatusType !== void 0 ? launchRequestStatusType : "start"]) }), _jsx(Progress, { indicating: true, progress: true, percent: percent })] }));
    return (_jsx("div", { style: {
            height: "100%",
        }, children: _jsx("div", { style: {
                left: "50%",
                minWidth: "300px",
                position: "absolute",
                top: "50%",
                transform: "translate(-50%, -50%)",
            }, children: _jsx("div", { style: { textAlign: "center" }, children: content }) }) }));
};
export default ProgressBar;
var humanReadableStatus = {
    start: "(1/6) Ładowanie danych",
    unknown: "(2/6) Nawiązywanie połączenia",
    accepted: "(3/6) Zaakceptowano, wysyłanie zapytania o model",
    queued: "(4/6) W kolejce",
    requested: "(5/6) Wysłano zapytanie o model",
    ready: "(6/6) Gotowe, uruchamianie sesji 3D",
    cancelled: "Cancelled",
    modelerror: "An issue with the model has occurred",
    serviced: "Viewing model",
    unavailable: "The requested model does not exist",
};
