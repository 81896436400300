import { createDescriptorData } from "../thestoreCommands";
export var commandPoorInternetQuality = function (configData, stage, messageJson) {
    var thestoreLogs = {
        configData: configData,
        event: "poor_internet_quality",
        level: "info",
        payload: {
            stage: stage,
            status: messageJson.command,
        },
    };
    var descriptorData = createDescriptorData(messageJson);
    return { descriptorData: descriptorData, thestoreLogs: thestoreLogs };
};
