var ClientJson = /** @class */ (function () {
    function ClientJson() {
    }
    return ClientJson;
}());
export { ClientJson };
var ClientOptions = /** @class */ (function () {
    function ClientOptions() {
        // Overridable streamer options
        this.ForceRelay = false;
    }
    ClientOptions.prototype.isValid = function () {
        if (!this.ProjectId) {
            return false;
        }
        if (!this.ModelId) {
            return false;
        }
        return true;
    };
    return ClientOptions;
}());
export { ClientOptions };
var ConfigData = /** @class */ (function () {
    function ConfigData() {
    }
    return ConfigData;
}());
export { ConfigData };
