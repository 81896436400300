// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demoHeader {
    position: absolute;
    left:50%;
    top: 60%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    flex-wrap: nowrap;
}

.brandLogo, .edgeLogo {
    margin: 10px 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.brandLogo img, .edgeLogo img {
    max-width: 200px;
}

@media only screen and (max-height: 550px) {
    .demoHeader {
        top: 70%;
    }
}

@media only screen and (max-width: 400px) {
    .brandLogo, .edgeLogo {
        margin: 5px 10px;
    }
    .brandLogo img, .edgeLogo img {
        max-width: 120px;
    }
}
`, "",{"version":3,"sources":["webpack://./src/components/DemoHeader.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,QAAQ;IACR,QAAQ;IACR,gCAAgC;IAChC,aAAa;IACb,uBAAuB;IACvB,mBAAmB;IACnB,mBAAmB;IACnB,iBAAiB;AACrB;;AAEA;IACI,iBAAiB;IACjB,aAAa;IACb,sBAAsB;IACtB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,gBAAgB;AACpB;;AAEA;IACI;QACI,QAAQ;IACZ;AACJ;;AAEA;IACI;QACI,gBAAgB;IACpB;IACA;QACI,gBAAgB;IACpB;AACJ","sourcesContent":[".demoHeader {\n    position: absolute;\n    left:50%;\n    top: 60%;\n    transform: translate(-50%, -50%);\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    flex-direction: row;\n    flex-wrap: nowrap;\n}\n\n.brandLogo, .edgeLogo {\n    margin: 10px 20px;\n    display: flex;\n    flex-direction: column;\n    align-items: center;\n    justify-content: center;\n}\n\n.brandLogo img, .edgeLogo img {\n    max-width: 200px;\n}\n\n@media only screen and (max-height: 550px) {\n    .demoHeader {\n        top: 70%;\n    }\n}\n\n@media only screen and (max-width: 400px) {\n    .brandLogo, .edgeLogo {\n        margin: 5px 10px;\n    }\n    .brandLogo img, .edgeLogo img {\n        max-width: 120px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
