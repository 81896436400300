import Cookies from "js-cookie";
import logThestore from "./logThestore";
export function logStatusChange(configData, status, message, streamerStatus, stage) {
    var cookieName = "status_pureweb";
    var oldStatus = Cookies.get(cookieName);
    var newStatus = status + " " + streamerStatus;
    if (oldStatus !== newStatus) {
        logThestore({
            configData: configData,
            event: "status_pureweb",
            level: "info",
            payload: {
                stage: stage,
                status: status,
                message: message,
                streamerStatus: streamerStatus,
            },
        });
        var expireDate = new Date();
        expireDate.setHours(expireDate.getHours() + 1);
        Cookies.set(cookieName, newStatus, {
            expires: expireDate,
            path: "",
        });
    }
}
