import qs from "qs";
import { isMobile } from "react-device-detect";
import { b64DecodeUnicode, b64EncodeUnicode } from "./base64";
export var getQueryBootString = function () {
    var _a, _b;
    var query = qs.parse(window.location.search.replace(/^\?/, ""));
    var encodedQuery = (_b = (_a = query.query) === null || _a === void 0 ? void 0 : _a.toString()) !== null && _b !== void 0 ? _b : "";
    if (encodedQuery === "") {
        return undefined;
    }
    return addMobileParam(encodedQuery);
};
export var addMobileParam = function (queryBootString) {
    var oldQueryString = b64DecodeUnicode(queryBootString);
    var newQueryString = "";
    if (oldQueryString.includes("-mobile=")) {
        newQueryString = oldQueryString.replace(/-mobile=[0-9"]*/i, '-mobile="' + (isMobile ? "1" : "0") + '"');
    }
    else {
        newQueryString =
            oldQueryString + ' -mobile="' + (isMobile ? "1" : "0") + '"';
    }
    return b64EncodeUnicode(newQueryString);
};
