var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { jsx as _jsx } from "react/jsx-runtime";
import "./../App.css";
import { DefaultStreamerOptions, StreamerStatus, UndefinedModelDefinition, } from "@pureweb/platform-sdk";
import { useLaunchRequest, useStreamer } from "@pureweb/platform-sdk-react";
import qs from "query-string";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import useAsyncEffect from "use-async-effect";
import logger from "../Log";
import { logStatusChange } from "../utilities/logPureweb";
import logThestore from "../utilities/logThestore";
import { useApp } from "./AppContext";
import EmbeddedView from "./EmbeddedView";
import { doRedirect } from "../utilities/doRedirect";
import { commandTheStore } from "../utilities/thestoreCommands";
var PureWeb = function (properties) {
    var _a, _b;
    var _c = useState(false), modelDefinitionUnavailable = _c[0], setModelDefinitionUnavailable = _c[1];
    var _d = useState(new UndefinedModelDefinition()), modelDefinition = _d[0], setModelDefinition = _d[1];
    var _e = useState([]), receivedMessages = _e[0], setReceivedMessages = _e[1];
    var _f = useState(), availableModels = _f[0], setAvailableModels = _f[1];
    var _g = useState(), launchRequestError = _g[0], setLaunchRequestError = _g[1];
    var t = useTranslation().t;
    var _h = useApp(), setLoaderStatus = _h.setLoaderStatus, setStreamerStatus = _h.setStreamerStatus, setLaunchRequestStatusType = _h.setLaunchRequestStatusType, setErrorInfo = _h.setErrorInfo, setInfo = _h.setInfo, setStage = _h.setStage, stage = _h.stage, setPointerLockRelease = _h.setPointerLockRelease, setUsePointerLock = _h.setUsePointerLock;
    var streamerOptions = DefaultStreamerOptions;
    var clientOptions = properties.clientOptions, platform = properties.platform, configData = properties.configData;
    var query = qs.parse(window.location.search);
    useAsyncEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        var models, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!clientOptions.ProjectId) return [3 /*break*/, 6];
                    logger.info("Initializing available models: " + clientOptions.ProjectId);
                    _a.label = 1;
                case 1:
                    _a.trys.push([1, 5, , 6]);
                    return [4 /*yield*/, platform.useAnonymousCredentials(clientOptions.ProjectId, clientOptions.EnvironmentId)];
                case 2:
                    _a.sent();
                    return [4 /*yield*/, platform.connect()];
                case 3:
                    _a.sent();
                    logger.info("Agent Connected: " + platform.agent.id);
                    streamerOptions.iceServers =
                        platform.agent.serviceCredentials.iceServers;
                    streamerOptions.forceRelay = clientOptions.ForceRelay;
                    return [4 /*yield*/, platform.getModels()];
                case 4:
                    models = _a.sent();
                    setAvailableModels(models);
                    logger.debug("Available models", models);
                    return [3 /*break*/, 6];
                case 5:
                    error_1 = _a.sent();
                    logger.error(error_1);
                    setLaunchRequestError(error_1);
                    logThestore({
                        configData: configData,
                        event: "error_initializing_models",
                        level: "error",
                        payload: {
                            stage: stage,
                            message: typeof error_1 === "object" ? error_1 === null || error_1 === void 0 ? void 0 : error_1.toString() : error_1,
                        },
                    });
                    return [3 /*break*/, 6];
                case 6: return [2 /*return*/];
            }
        });
    }); }, [clientOptions]);
    useEffect(function () {
        if (availableModels === null || availableModels === void 0 ? void 0 : availableModels.length) {
            var selectedModels = availableModels.filter(function (model) {
                if (clientOptions.ModelId === model.id) {
                    // If there is a version specified and we encounter it
                    if (clientOptions.Version &&
                        clientOptions.Version === model.version) {
                        return true;
                    }
                    // If there is no version specified and we find the primary version
                    if (!clientOptions.Version && model.active) {
                        return true;
                    }
                }
                return false;
            });
            if (selectedModels === null || selectedModels === void 0 ? void 0 : selectedModels.length) {
                setModelDefinition(selectedModels[0]);
            }
            else {
                setModelDefinitionUnavailable(true);
            }
        }
    }, [availableModels, clientOptions]);
    var launchRequestOptions = {
        virtualizationProviderOverride: query.virtualizationProviderOverride,
    };
    var _j = useLaunchRequest(platform, modelDefinition, launchRequestOptions), status = _j[0], launchRequest = _j[1], queueLaunchRequest = _j[2];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _k = useStreamer(platform, launchRequest, streamerOptions), streamerStatus = _k[0], emitter = _k[1], videoStream = _k[2], audioStream = _k[3], messageSubject = _k[4];
    var _l = useState(false), loading = _l[0], setLoading = _l[1];
    useEffect(function () {
        if (streamerStatus === StreamerStatus.Failed) {
            platform.disconnect();
        }
    }, [streamerStatus, platform]);
    useAsyncEffect(function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (!modelDefinitionUnavailable && modelDefinition.id) {
                setLoading(true);
                if (clientOptions.LaunchType !== "local") {
                    try {
                        queueLaunchRequest();
                    }
                    catch (error) {
                        setLaunchRequestError(error);
                    }
                }
            }
            return [2 /*return*/];
        });
    }); }, [modelDefinitionUnavailable, modelDefinition]);
    var handleUnhandledRejection = function (promiseRejectionEvent) {
        var _a, _b;
        logThestore({
            configData: configData,
            event: "error_pureweb",
            level: "error",
            payload: {
                stage: stage,
                message: "unhandledrejection: " + promiseRejectionEvent.reason,
            },
        });
        setErrorInfo({
            uid: (_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
            button: t("Ok, rozumiem"),
            buttonUrl: window.location.href,
            message: t("Wystąpił problem podczas połączenia z platformą streamingową. Proszę odświeżyć stronę by rozpocząć badanie."),
        });
    };
    useEffect(function () {
        window.addEventListener("unhandledrejection", handleUnhandledRejection, false);
        return function () {
            window.removeEventListener("unhandledrejection", handleUnhandledRejection);
        };
    }, [handleUnhandledRejection]);
    useEffect(function () {
        setStreamerStatus(streamerStatus);
        setLaunchRequestStatusType(status.status);
        logger.info("Status", status, streamerStatus);
        var launchRequestId = status.launchRequestId;
        var message = status.message +
            (launchRequestId !== undefined
                ? " launchRequestId: " + launchRequestId
                : "");
        logStatusChange(configData, status.status, message, streamerStatus, stage);
    }, [status, streamerStatus, configData]);
    useEffect(function () {
        var subscription = messageSubject.subscribe(function (value) { return __awaiter(void 0, void 0, void 0, function () {
            var messageJson;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        logger.info("Message: " + value);
                        messageJson = JSON.parse(value);
                        return [4 /*yield*/, commandTheStore(configData, messageJson, stage, setStage, emitter, setInfo, setErrorInfo, setLoaderStatus, setUsePointerLock, setPointerLockRelease, receivedMessages, setReceivedMessages)];
                    case 1:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); }, function (error) {
            logger.error(error);
        });
        return function () {
            subscription.unsubscribe();
        };
    }, [messageSubject, configData]);
    useEffect(function () {
        var _a, _b, _c, _d;
        if (!clientOptions.isValid() && clientOptions.LaunchType !== "local") {
            logThestore({
                configData: configData,
                event: "error_pureweb",
                level: "error",
                payload: {
                    stage: stage,
                    message: "Client has one or more configuration errors",
                },
            });
            setErrorInfo({
                uid: (_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
                message: t("Wystąpił błąd techniczny. Skontaktuj się z realizatorem badania i zgłoś, że otrzymany przez Ciebie link do badania jest niepoprawny. Obecnie badanie jest wstrzymane."),
            });
        }
        if (modelDefinitionUnavailable && clientOptions.LaunchType !== "local") {
            logThestore({
                configData: configData,
                event: "error_pureweb",
                level: "error",
                payload: {
                    stage: stage,
                    message: "The model that you have requested does not exist",
                },
            });
            setErrorInfo({
                uid: (_d = (_c = configData === null || configData === void 0 ? void 0 : configData.content) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.uid,
                message: t("Wystąpił błąd techniczny. Skontaktuj się z realizatorem badania i zgłoś, że otrzymany przez Ciebie link do badania jest niepoprawny. Obecnie badanie jest wstrzymane."),
            });
        }
    }, [clientOptions, modelDefinitionUnavailable]);
    useEffect(function () {
        var _a, _b;
        if (launchRequestError) {
            logThestore({
                configData: configData,
                event: "error_pureweb",
                level: "error",
                payload: {
                    stage: stage,
                    message: "There was an error with the launch request: " +
                        JSON.stringify(launchRequestError),
                },
            });
            setErrorInfo({
                uid: (_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
                message: t("Wystąpił problem podczas połączenia z platformą streamingową. Proszę odświeżyć stronę by rozpocząć badanie."),
            });
        }
    }, [launchRequestError]);
    useEffect(function () {
        var _a, _b, _c, _d, _e, _f, _g;
        if (streamerStatus === StreamerStatus.NotSupported) {
            logThestore({
                configData: configData,
                event: "error_browser_not_supported",
                level: "warning",
                payload: {
                    stage: stage,
                },
            });
            setErrorInfo({
                uid: (_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
                message: t("Twoja przeglądarka nie jest obsługiwana"),
            });
        }
        if (streamerStatus === StreamerStatus.Failed) {
            setErrorInfo({
                uid: (_d = (_c = configData === null || configData === void 0 ? void 0 : configData.content) === null || _c === void 0 ? void 0 : _c.user) === null || _d === void 0 ? void 0 : _d.uid,
                button: t("Ok, rozumiem"),
                buttonUrl: window.location.href,
                message: t("Wystąpił problem podczas połączenia z platformą streamingową. Proszę odświeżyć stronę by rozpocząć badanie."),
            });
        }
        if (streamerStatus === StreamerStatus.Withdrawn) {
            setTimeout(function () {
                var _a, _b, _c;
                doRedirect((_c = (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.redirect) === null || _b === void 0 ? void 0 : _b.userError) !== null && _c !== void 0 ? _c : "");
            }, 3000);
            setInfo({
                button: t("Wróć do badania"),
                buttonUrl: (_g = (_f = (_e = configData.content) === null || _e === void 0 ? void 0 : _e.redirect) === null || _f === void 0 ? void 0 : _f.userError) !== null && _g !== void 0 ? _g : "",
                message: t("Ankieta w sklepie wirtualnym zakończyła się"),
                messageBottom: t("Nastąpi przekierowanie do pozostałej części ankiety. Jeśli nie wydarzy się to automatycznie, proszę kliknąć >wróć do badania<}/"),
            });
        }
    }, [streamerStatus]);
    if (loading) {
        return (_jsx(EmbeddedView, { InputEmitter: emitter, LaunchRequestStatus: status, PointerLockRelease: clientOptions.PointerLockRelease, Resolution: clientOptions.Resolution, StreamerStatus: streamerStatus, UseNativeTouchEvents: clientOptions.UseNativeTouchEvents, UsePointerLock: clientOptions.UsePointerLock, VideoStream: videoStream }));
    }
    if (clientOptions.LaunchType !== "local" &&
        availableModels &&
        !(availableModels === null || availableModels === void 0 ? void 0 : availableModels.length)) {
        logThestore({
            configData: configData,
            event: "error_pureweb",
            level: "error",
            payload: {
                stage: stage,
                message: "No models are currently available in this environment",
            },
        });
        setErrorInfo({
            uid: (_b = (_a = configData === null || configData === void 0 ? void 0 : configData.content) === null || _a === void 0 ? void 0 : _a.user) === null || _b === void 0 ? void 0 : _b.uid,
            message: t("Wystąpił błąd techniczny. Skontaktuj się z realizatorem badania i zgłoś, że otrzymany przez Ciebie link do badania jest niepoprawny. Obecnie badanie jest wstrzymane."),
        });
    }
    return _jsx("div", {});
};
export default PureWeb;
