import { jsx as _jsx } from "react/jsx-runtime";
import "semantic-ui-css/semantic.min.css";
import "./index.css";
import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { AppProvider } from "./components/AppContext";
var container = document.querySelector("#root");
var root = createRoot(container);
root.render(_jsx(AppProvider, { children: _jsx(App, {}) }));
