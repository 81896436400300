import isDemo from "../utilities/isDemo";
function getRedirectUrl(configData, messageJson) {
    var _a, _b, _c;
    var redirectUrl;
    if (isDemo) {
        redirectUrl = (_c = (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.demo) === null || _b === void 0 ? void 0 : _b.redirectUrl) !== null && _c !== void 0 ? _c : "";
    }
    else {
        redirectUrl = getProjectRedirectUrl(configData, messageJson);
    }
    return redirectUrl;
}
function getProjectRedirectUrl(configData, messageJson) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l;
    var redirectUrl;
    if (((_a = messageJson === null || messageJson === void 0 ? void 0 : messageJson.params) === null || _a === void 0 ? void 0 : _a.status) === "completed") {
        redirectUrl = (_d = (_c = (_b = configData.content) === null || _b === void 0 ? void 0 : _b.redirect) === null || _c === void 0 ? void 0 : _c.success) !== null && _d !== void 0 ? _d : "";
    }
    else if (((_e = messageJson === null || messageJson === void 0 ? void 0 : messageJson.params) === null || _e === void 0 ? void 0 : _e.status) === "error_user") {
        redirectUrl = (_h = (_g = (_f = configData.content) === null || _f === void 0 ? void 0 : _f.redirect) === null || _g === void 0 ? void 0 : _g.userError) !== null && _h !== void 0 ? _h : "";
    }
    else {
        // default error_tech
        redirectUrl = (_l = (_k = (_j = configData.content) === null || _j === void 0 ? void 0 : _j.redirect) === null || _k === void 0 ? void 0 : _k.technicalError) !== null && _l !== void 0 ? _l : "";
    }
    return redirectUrl;
}
export default getRedirectUrl;
