import isDemo from "./isDemo";
export default function setupAbtShield(configData) {
    var _a, _b, _c, _d, _e, _f;
    var event = new CustomEvent("changeAbtShield", {
        detail: [
            "create",
            (_b = (_a = configData.content) === null || _a === void 0 ? void 0 : _a.research) === null || _b === void 0 ? void 0 : _b.instanceName,
            {
                timezone: "Europe/Warsaw",
            },
        ],
    });
    window.dispatchEvent(event);
    var eventPage = new CustomEvent("changeAbtShield", {
        detail: [
            "send",
            "page_view",
            {
                customParams: {
                    prid: (_d = (_c = configData.content) === null || _c === void 0 ? void 0 : _c.research) === null || _d === void 0 ? void 0 : _d.prid,
                    respid: (_f = (_e = configData.content) === null || _e === void 0 ? void 0 : _e.user) === null || _f === void 0 ? void 0 : _f.respId,
                },
            },
        ],
    });
    window.dispatchEvent(eventPage);
}
export function pushAbtShield(arrayToPush) {
    if (!isDemo) {
        var event = new CustomEvent("changeAbtShield", { detail: arrayToPush });
        window.dispatchEvent(event);
    }
}
