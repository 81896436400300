import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from "react";
import { Button } from "semantic-ui-react";
import { useApp } from "./AppContext";
import { useTranslation } from "react-i18next";
export var ErrorInfoContainer = function () {
    var errorInfo = useApp().errorInfo;
    var t = useTranslation().t;
    return errorInfo ? (_jsxs("div", { style: {
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            height: "100%",
            justifyContent: "center",
            overflow: "none",
            textAlign: "center",
        }, children: [_jsx("h2", { style: { fontWeight: "normal", padding: "10px" }, dangerouslySetInnerHTML: { __html: errorInfo.message } }), errorInfo.button && (_jsx("div", { children: _jsx(Button, { primary: true, as: "a", href: errorInfo.buttonUrl, children: _jsx(Button.Content, { visible: true, children: errorInfo.button }) }) })), errorInfo.messageBottom && _jsx("h3", { children: errorInfo.messageBottom }), errorInfo.uid && (_jsxs("p", { style: {
                    fontSize: "calc(100% - 1px)",
                    marginTop: "20px",
                }, children: [t("Prześlij realizatorowi badania kod błędu:"), " ", errorInfo.uid] }))] })) : (_jsx("div", {}));
};
